/**
    倒计时
 */
import React, { useEffect, useState, useCallback } from "react";
export default ({
  title = "",
  prefix = "重新获取",
  duration = 60,
  ticker = 1, //每次减少量
  size = "large",
  className = "",
  flag = false, //是否开启倒计时
  disabled = false,
  onClick = () => {},
  reset = () => {},
}) => {
  const [time, setTime] = useState(duration);
  const [captchaIns, setCaptchaIns] = useState(null);
  const verify = useCallback(() => {
    if (captchaIns) {
      captchaIns.refresh();
      captchaIns.popUp();
    }
  }, [captchaIns]);
  // const languages = {
  //   "zh-cn": "zh-CN",
  //   "en-us": "en",
  //   "vi-vn": "vi",
  // };

  const handleValidatePass = useCallback((validate) => {
    if (!validate) {
      return;
    }
    onClick(validate);
  },  [onClick]);

  useEffect(() => {
    function getTimestamp(msec) {
      msec = !msec && msec !== 0 ? msec : 1;
      return parseInt(new Date().valueOf() / msec, 10);
    }

    function loadScript(src) {
      return new Promise((resolve, reject) => {
        const head = document.head || document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        if (!("onload" in script)) {
          script.onreadystatechange = function () {
            if (this.readyState !== "complete" && this.readyState !== "loaded")
              return;
            this.onreadystatechange = null;
            resolve(script);
          };
        }

        script.onload = function () {
          this.onload = null;
          resolve(script);
        };
        script.onerror = function () {
          reject(script);
        };
        head.appendChild(script);
      });
    }
    const url =
      "https://cstaticdun.126.net/load.min.js" +
      "?t=" +
      getTimestamp(1 * 60 * 1000);
    loadScript(url)
      .then((res) => {
        window.initNECaptcha &&
          window.initNECaptcha(
            {
              captchaId: "0f9c2f5c74534c7e924ef9935ab8d509",
              element: "#root",
              mode: "popup",
              width: "320px",
              lang: "zh-CN",
              onReady: function () {},
              onVerify: function (err, data) {
                if (err) {
                  return;
                }
                handleValidatePass(data.validate);
              },
            },
            function (instance) {
              setCaptchaIns(instance);
            }
          );
      })
      .catch((err) => {});
  }, [handleValidatePass]);

  useEffect(() => {
    function cutDown(timer) {
      setTime((time) => {
        if (time <= 1) {
          clearInterval(timer);
          reset();
          return duration;
        }
        return time - 1;
      });
    }
    let timer;
    if (flag) {
      timer = setInterval(() => cutDown(timer), ticker * 1000);
    } else {
      clearInterval(timer);
      setTime(duration);
    }

    return () => clearInterval(timer);
  }, [flag, duration, reset, ticker]);

  return (
    <button
      type="button"
      onClick={() => verify()}
      disabled={disabled || time < duration}
      className={className}
      size={size}
    >
      {time === duration || time < 1 ? title : `${prefix}(${time})s`}
    </button>
  );
};
