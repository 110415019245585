function isLocalHost(hostname = window.location.hostname) {
  return ["localhost", "127.0.0.1", "", "::1"].includes(hostname);
}

function createLinkConfig() {
  let linkConfig = {
    web: "/",
    h5: "/",
    online:
      "https://shunlong.s5.udesk.cn/im_client/?web_plugin_id=1538&group_id=1048",
    login: "https://fo.wsgoldfo002.com/Frontoffice/",
    disclaimer: `/singlepage/disclaimer`,
    risk: `/singlepage/risk_disclosure`,
    agreement: `/singlepage/client_agreement`,
    policy: `/singlepage/privacy_policy`,
    ios: "https://apps.apple.com/app/apple-store/id1630694317",
    android: [
      "https://downloads.hsajrrysc.com/source/material/HSJY_tuijian.apk",
      "https://downloads.hsjydol.com/source/material/HSJY_tuijian.apk",
    ],
    android_backup:
      "https://downloads.hsjydol.com/source/material/HSJY_tuijian.apk",
  };

  /**
   * 本地开发或者UAT 设定:
   * isLocalHost() || window.location.hostname.includes("atbgovernor")
   *
   * 其余视为正式环境，动态域名
   */
  if (isLocalHost() || window.location.hostname.includes("atbgovernor")) {
    linkConfig.h5 = "http://h5-hsha-noweb.atbgovernor.com";
  } else {
    linkConfig.h5 = window.location.origin.replace("acat", "h5");
  }

  return linkConfig;
}

const sourceUrl = "_s_u_l_";
const linkConfig = createLinkConfig();
export { linkConfig, sourceUrl };
