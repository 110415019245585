import Request from "./request";
const prefix = "";

/**
 * 验证手机号码
 * @param {string} mobilePhone
 * @param {string} mobilePrefix
 */
export const validatePhone = (mobilePhone, mobilePrefix = "86") =>
  Request({
    method: "post",
    url: `${prefix}/customer/checkPhone`,
    body: {
      mobilePhone,
      mobilePrefix,
    },
  });

/**
 * 发送验证码
 * @param {string} mobilePhone 用户手机号
 * @param {string} signature 短信签名(默认BT Global,传jn为金牛财富)
 * @param {string} validate 易盾检验码
 * @param {string} trackParameters 跟踪信息
 */
export const sendOpenMessage = (params = {}) =>
  Request({
    method: "post",
    url: `${prefix}/customer/sendOpenMessage`,
    body: {
      signature: "hsjy",

      ...params,
    },
  });

/**
 * 注册真实用户
 * @param {string} mobilePhone 用户手机号
 * @param {string} password 用户密码
 * @param {string} userLevel 用户等级(默认MIN)
 * @param {int} driverType 用户来源设备(1:网页-PC;2-网页-Android;3-网页-IOS)
 * @param {string} captcha 用户开户验证码
 * @param {string} channelName 开户渠道来源
 * @param {string} signature 短信签名(默认BT Global,传jn为金牛财富)
 * @param {string} inviteCode 邀请码
 */
export const createAccount = (params = {}) =>
  Request({
    method: "post",
    url: `${prefix}/customer/createAccount`,
    body: {
      driverType: 1,
      userLevel: "MIN",
      signature: "hsjy",
      ...params,
    },
  });

/**
 * 完善资料
 * @param {string} chineseName  用户姓名
 * @param {string} email  用户邮箱
 * @param {string} idDocumentNumber  用户身份证号码
 * @param {int} updateCustomerUuid  完善资料UUID
 */
export const updateAccount = (params = {}) =>
  Request({
    method: "post",
    url: `${prefix}/customer/updateAccount`,
    body: {
      ...params,
    },
  });

/**
 * 获取自动登录链接
 * @param {int} updateCustomerUuid
 */
export const getAuthUrl = (updateCustomerUuid) =>
  Request({
    method: "post",
    url: `${prefix}/customer/generateUrl`,
    body: {
      updateCustomerUuid,
    },
  });

/**
 * 获取新闻详情
 */
export const getNewsDetail = async (id) =>
  Request({
    url: `${prefix}/news/${id}`,
  });
